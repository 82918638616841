// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-image {
        flex: none;
        width: 12rem;
        height: 12rem;
        margin-bottom: 1rem;
        object-fit: contain;
        object-position: center;
        opacity: 0.9;
    }

    .error-message {
        flex: none;
        padding: 0 3rem;
        font-size: 2rem;
        max-height: 3.6em;
        text-align: center;
        color: @color-surface-light5-90;
    }

    .buttons-container {
        flex: none;
        align-self: stretch;
        margin: 0 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .button-container {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 2rem 1rem 0;
            padding: 0 1rem;
            min-width: 8rem;
            height: 3rem;
            background-color: @color-accent3;

            &:hover {
                background-color: @color-accent3-light1;
            }

            &:global(.disabled) {
                background-color: @color-surface-dark5;
            }

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                max-height: 2.4em;
                font-size: 1.1rem;
                font-weight: 500;
                text-align: center;
                color: @color-surface-light5-90;
            }
        }
    }
}