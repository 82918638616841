// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/PlayIconCircleCentered/styles.less') {
    play-icon-circle-centered-background: background;
    play-icon-circle-centered-icon: icon;
}

.stream-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;

    &:hover, &:focus {
        background-color: @color-background;
    }

    &:hover, &:focus, &:global(.selected) {
        .play-icon {
            .play-icon-circle-centered-background {
                fill: @color-accent4-90;
            }

            .play-icon-circle-centered-icon {
                fill: @color-surface-light5-90;
            }
        }
    }

    .thumbnail-container, .addon-name-container {
        flex: none;

        .thumbnail {
            display: block;
            width: 5rem;
            height: 5rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .placeholder-icon {
            display: block;
            width: 5rem;
            height: 5rem;
            padding: 0.5rem;
            fill: @color-secondaryvariant1-light3-90;
        }

        .addon-name {
            width: 5rem;
            max-height: 3.6em;
            font-size: 1.1rem;
            text-align: center;
            color: @color-surface-light5-90;
        }
    }

    .info-container {
        flex: 1;
        max-height: 3.6em;
        margin: 0.5rem 1rem;
        white-space: pre;
        color: @color-surface-light5-90;
    }

    .play-icon {
        flex: none;
        width: 3.5rem;
        height: 5rem;

        .play-icon-circle-centered-background {
            fill: none;
        }

        .play-icon-circle-centered-icon {
            fill: @color-surface-dark5-90;
        }
    }

    .progress-bar-container {
        flex: 0 0 100%;
        background-color: @color-background-light2;

        .progress-bar {
            height: 0.5rem;
            background-color: @color-primaryvariant1;
        }
    }
}