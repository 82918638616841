// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.not-found-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: @color-background-dark2;

    .nav-bar {
        flex: none;
        align-self: stretch;
    }

    .not-found-content {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .not-found-image {
            flex: none;
            width: 12rem;
            height: 12rem;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .not-found-label {
            flex: none;
            font-size: 2.5rem;
            max-height: 3.6em;
            max-width: 60%;
            text-align: center;
            color: @color-secondaryvariant2-light1-90;
        }
    }
}