// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.videos-list-container {
    display: flex;
    flex-direction: column;

    .message-container {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        overflow-y: auto;

        .image {
            flex: none;
            width: 10rem;
            height: 10rem;
            max-width: 100%;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .label {
            flex: none;
            font-size: 1.4rem;
            text-align: center;
            color: @color-secondaryvariant2-light1-90;
        }
    }

    .seasons-bar {
        flex: none;
        align-self: stretch;
    }

    .search-bar {
        flex: none;
        align-self: stretch;
        margin: 0 1.5rem 1rem 1.5rem;

        &:first-child {
            margin-top: 1rem;
        }
    }

    .videos-container {
        flex: 1;
        align-self: stretch;
        overflow-y: auto;
    }
}