// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/Popup/styles.less') {
    popup-menu-container: menu-container;
}

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
    multiselect-option-container: option-container;
    multiselect-option-label: label;
}

:import('~stremio/common/PlayIconCircleCentered/styles.less') {
    play-icon-circle-centered-background: background;
    play-icon-circle-centered-icon: icon;
}

.meta-item-container {
    padding: 1rem;
    overflow: visible;

    &:hover, &:focus, &:global(.active), &:global(.selected) {
        outline-style: none;
        background-color: @color-background-light3;
        transition: background-color 100ms ease-out;
    }

    &.poster-shape-poster {
        .poster-container {
            padding-top: calc(100% * var(--poster-shape-ratio));
        }
    }

    &.poster-shape-square {
        .poster-container {
            padding-top: 100%;
        }
    }

    &.poster-shape-landscape {
        .poster-container {
            padding-top: calc(100% * var(--landscape-shape-ratio));
        }
    }

    .poster-container {
        position: relative;
        z-index: 0;
        background-color: @color-background;

        .poster-image-layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -3;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .poster-image {
                flex: none;
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                opacity: 0.9;
            }

            .placeholder-icon {
                flex: none;
                width: 80%;
                height: 50%;
                fill: @color-background-light3-90;
            }
        }

        .play-icon-layer {
            position: absolute;
            top: 30%;
            right: 0;
            bottom: 30%;
            left: 0;
            z-index: -2;
            overflow: visible;

            .play-icon {
                display: block;
                width: 100%;
                height: 100%;
                filter: drop-shadow(0 0 0.5rem @color-background);

                .play-icon-circle-centered-background {
                    fill: @color-accent4-90;
                }

                .play-icon-circle-centered-icon {
                    fill: @color-surface-light5-90;
                }
            }
        }

        .progress-bar-layer {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-color: @color-background-light2;

            .progress-bar {
                height: 0.4rem;
                background-color: @color-primaryvariant1;
            }
        }
    }

    .title-bar-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2.8rem;
        overflow: visible;

        .title-label {
            flex: 1;
            max-height: 2.4em;
            padding-left: 0.5rem;
            color: @color-surface-light5-90;

            &:only-child {
                padding-right: 0.5rem;
            }
        }

        .menu-label-container {
            flex: none;
            width: 1.5rem;
            height: 2.8rem;
            padding: 1rem 0;
            background-color: transparent;

            .icon {
                display: block;
                width: 100%;
                height: 100%;
                fill: @color-surface-light1-90;
            }

            .popup-menu-container {
                width: auto;

                .multiselect-menu-container {
                    min-width: 6rem;
                    max-width: 12rem;

                    .multiselect-option-container {
                        padding: 0.5rem;
                        background-color: @color-surface-light5;

                        &:hover, &:focus {
                            outline: none;
                            background-color: @color-surface-light2;
                        }

                        .multiselect-option-label {
                            color: @color-background-dark5-90;
                        }
                    }
                }
            }
        }
    }
}