// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/Popup/styles.less') {
    popup-menu-container: menu-container;
}

.label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    background-color: @color-background;

    &:global(.active) {
        .label {
            color: @color-surface-light5-90;
        }

        .icon {
            fill: @color-surface-light5-90;
        }
    }

    >.label {
        flex: 1;
        max-height: 2.4em;
        font-weight: 500;
        color: @color-secondaryvariant1-90;
    }

    .icon {
        flex: none;
        width: 1rem;
        height: 1rem;
        margin-left: 1rem;
        fill: @color-secondaryvariant1-90;
    }

    .popup-menu-container {
        width: 100%;
    }
}

.modal-container, .popup-menu-container {
    .menu-container {
        .option-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1rem;
            background-color: @color-background;

            &:global(.selected) {
                .icon {
                    display: block;
                }
            }

            &:hover, &:focus {
                background-color: @color-background-light2;
            }

            .label {
                flex: 1;
                max-height: 4.8em;
                color: @color-surface-light5-90;
            }

            .icon {
                flex: none;
                display: none;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 100%;
                margin-left: 1rem;
                background-color: @color-accent3-90;
            }
        }

        .no-options-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background-color: @color-background;

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                font-size: 1.2rem;
                font-weight: 500;
                text-align: center;
                color: @color-surface-light5-90;
            }
        }
    }
}