// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
}

:import('~stremio/common/ModalDialog/styles.less') {
    addon-modal-content: modal-dialog-content;
    cancel-button-label: label;
}

.addons-container {
    width: 100%;
    height: 100%;
    background-color: @color-background-dark2;

    .addons-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-self: stretch;

        .selectable-inputs-container {
            flex: none;
            align-self: stretch;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1.5rem;
            overflow: visible;

            .add-button-container {
                flex: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 10rem;
                height: 3.5rem;
                margin-right: 1.5rem;
                padding: 0 1rem;
                background-color: @color-accent3;

                &:hover {
                    background-color: @color-accent3-light2;
                }

                &:focus {
                    outline-color: @color-surface-light5;
                }

                .icon {
                    flex: none;
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: 1rem;
                    fill: @color-surface-light5;
                }

                .add-button-label {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    max-height: 2.4em;
                    font-size: 1.1rem;
                    color: @color-surface-light5;
                }
            }

            .select-input-container {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 15rem;
                height: 3.5rem;
                margin-right: 1.5rem;

                .multiselect-menu-container {
                    max-height: calc(3.2rem * 7);
                    overflow: auto;
                }
            }

            .spacing {
                flex: 1;
            }

            .search-bar {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 18rem;
            }
        }

        .message-container {
            flex: 1;
            align-self: stretch;
            padding: 0 1.5rem;
            font-size: 2rem;
            color: @color-surface-light5;
        }

        .addons-list-container {
            flex: 1;
            align-self: stretch;
            padding: 0 1.5rem;
            overflow-y: auto;

            .addon {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.add-addon-modal-container {
    .addon-modal-content {
        width: 30rem;

        .notice {
            margin-bottom: 1.5rem;
            font-size: 1rem;
            color: @color-background-dark5-90;
        }

        .addon-url-input {
            width: 100%;
            padding: 1rem;
            color: @color-surface-dark5;
            outline: var(--focus-outline-size) solid @color-surface-light2;
            outline-offset: calc(-1 * var(--focus-outline-size));

            &:hover {
                outline-color: @color-surface-light4;
            }

            &:focus {
                outline-color: @color-background-dark5;
            }
        }
    }

    .cancel-button {
        background-color: transparent;

        &:hover {
            background-color: @color-surface-light3;
        }

        &:focus {
            outline-color: @color-background-dark5;
        }

        .cancel-button-label {
            color: @color-surface-dark2;
        }
    }
}

.share-modal-container {
    .addon-modal-content {
        width: 30rem;

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo, .icon {
                float: left;
                width: 5rem;
                height: 5rem;
                margin-right: 1.5rem;
                padding: 0.5rem;
            }

            .logo {
                object-fit: contain;
                object-position: center;
            }

            .icon {
                fill: @color-secondaryvariant1-light3;
            }

            .name-container {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: baseline;

                .name {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    margin-right: 0.5rem;
                    font-size: 1.6rem;
                    color: @color-background-dark5-90;
                }

                .version {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                    margin-top: 0.5rem;
                    color: @color-background-dark5-60;
                }
            }
        }

        .share-prompt-container {
            margin-top: 1rem;
        }
    }
}