// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.color-input-container {
    position: relative;
    z-index: 0;

    .transparent-label-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        border: thin solid @color-surface-light5-20;
        pointer-events: none;

        .transparent-label {
            flex: 1;
            text-align: center;
            color: @color-surface-light5;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.color-picker-container {
    margin: 1rem 0;
}