// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.streams-list-container {
    display: flex;
    flex-direction: column;

    .message-container {
        flex: 0 1 auto;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 1rem 0;
        overflow-y: auto;

        .image {
            flex: none;
            width: 10rem;
            height: 10rem;
            max-width: 100%;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .label {
            flex: none;
            font-size: 1.4rem;
            text-align: center;
            color: @color-secondaryvariant2-light1-90;
        }
    }

    .streams-container {
        flex: 0 1 auto;
        align-self: stretch;
        margin-top: 1rem;
        overflow-y: auto;
    }

    .install-button-container {
        flex: none;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 1rem;
        padding: 1.5rem 1rem;
        background-color: @color-accent3;

        &:hover, &:focus {
            background-color: @color-accent3-light1;
        }

        .icon {
            flex: none;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            fill: @color-surface-light5-90;
        }

        .label {
            flex: 0 1 auto;
            font-size: 1.5rem;
            font-weight: 500;
            max-height: 3.6em;
            text-align: center;
            color: @color-surface-light5-90;
        }
    }
}