// Copyright (C) 2017-2020 Smart code 203358507

:import('~stremio/common/PlayIconCircleCentered/styles.less') {
    play-icon-circle-centered-background: background;
    play-icon-circle-centered-icon: icon;
}

.stream-placeholder-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;

    .addon-container {
        flex: none;

        .addon-name {
            width: 5rem;
            height: 2rem;
            background-color: var(--color-placeholder-background);
        }
    }

    .info-container {
        flex: 1;
        margin: 0.5rem 1rem;

        .description-container {
            height: 1.2rem;
            background-color: var(--color-placeholder-background);

            &:nth-child(1) {
                width: 80%;
            }

            &:nth-child(2) {
                width: 40%;
                margin-top: 0.5rem;
            }
        }
    }

    .play-icon {
        flex: none;
        width: 3.5rem;
        height: 5rem;

        .play-icon-circle-centered-background {
            fill: none;
        }

        .play-icon-circle-centered-icon {
            fill: var(--color-placeholder-background);
        }
    }
}