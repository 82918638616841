// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.discrete-input-container {
    &:global(.disabled) {
        .header {
            color: @color-surface-90;
        }

        .input-container {
            opacity: 0.4;
        }
    }

    .header {
        max-height: 2.4em;
        margin-bottom: 0.5rem;
        color: @color-surface-light5-90;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: @color-background;

        .button-container {
            flex: none;
            width: 3rem;
            height: 3rem;
            padding: 1rem;
            background-color: @color-background-light2;

            .icon {
                display: block;
                width: 100%;
                height: 100%;
                fill: @color-surface-light5-90;
            }
        }

        .option-label {
            flex: 1;
            max-height: 2.4em;
            font-weight: 500;
            text-align: center;
            color: @color-surface-light5-90;
        }
    }
}