// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.action-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: @color-surface-light5-20;

    &:hover, &:focus {
        background-color: @color-accent3;
    }

    .icon-container {
        flex: 0 0 50%;
        align-self: stretch;
        padding-top: 15%;

        &:only-child {
            padding: 5% 0;
        }

        .icon {
            display: block;
            width: 100%;
            height: 100%;
            fill: @color-surface-light5-90;
        }
    }

    .label-container {
        flex: 0 0 50%;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
            flex: 1;
            font-weight: 500;
            max-height: 2.4em;
            padding: 0 0.2rem;
            text-align: center;
            color: @color-surface-light5-90;
        }
    }
}