// Copyright (C) 2017-2020 Smart code 203358507

.buffering-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .buffering-loader {
        flex: none;
        width: 17rem;
        height: 17rem;
    }
}