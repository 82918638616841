// Copyright (C) 2017-2020 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @color-background-dark5-40;

    .modal-dialog-container {
        flex: none;
        display: flex;
        flex-direction: column;
        max-width: 80%;
        max-height: 80%;
        background-color: @color-surface-light5;

        .close-button-container {
            flex: none;
            align-self: flex-end;
            width: 2rem;
            height: 2rem;
            margin: 0.2rem 0.2rem 0 0;
            padding: 0.5rem;

            .icon {
                display: block;
                width: 100%;
                height: 100%;
                fill: @color-surface-dark1-90;
            }

            &:hover, &:focus {
                .icon {
                    fill: @color-surface-light1-90;
                }
            }

            &:focus {
                outline-color: @color-background-dark5;
            }
        }

        .title-container {
            flex: 1 0 auto;
            max-height: 2.4em;
            margin: 0 2rem;
            font-size: 1.2rem;
            font-weight: 500;
            color: @color-background-dark5-90;
        }

        .modal-dialog-content {
            flex: 1;
            align-self: stretch;
            margin: 1.5rem 1rem 0;
            padding: 0 1rem;
            overflow-y: auto;

            &:last-child {
                margin-bottom: 2rem;
            }
        }

        .buttons-container {
            flex: none;
            align-self: stretch;
            margin: 2rem 2rem 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &:last-child {
                margin-bottom: 2rem;
            }
        }
    }
}

.action-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    background-color: @color-accent3;

    &:hover {
        background-color: @color-accent3-light1;
    }

    &:focus {
        outline-color: @color-background-dark5;
    }

    &:not(:last-child) {
        margin-right: 1rem;
    }

    .icon {
        flex: none;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .5rem;
        fill: @color-surface-light5-90;
    }

    .label {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        max-height: 3.6em;
        font-size: 1.1rem;
        font-weight: 500;
        text-align: center;
        color: @color-surface-light5-90;
    }
}